import { FlowConfig } from '@/components/SimplifiedSignup/Context/types';
import { FORTY_PERCENT_OFF_COUPON } from '@/components/app/layout/DiscountModal';

import LockedContentScreensConfig from './Screens';

const LockedContentFlow: FlowConfig = {
	activeScreenKey: 'account',
	activeScreenConfig: LockedContentScreensConfig.account,
	name: 'LockedContentUpsell',
	pageName: 'locked-content-upsell',
	screens: {
		account: LockedContentScreensConfig.account,
		payment: LockedContentScreensConfig.payment,
		handoff: LockedContentScreensConfig.handoff,
	},
	plan: 'yearly',
	coupon: FORTY_PERCENT_OFF_COUPON,
	discount: 0.4,
	allowChurned: true,
	hasFreeTrial: false,
	offerCountries: null,
	offerCurrencies: null,
	isWithinModal: true,
};

export default LockedContentFlow;
