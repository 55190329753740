import { MessageDescriptor } from 'react-intl';

import WebAppUpsellFlow from '@/components/SimplifiedSignup/configs/WebAppUpsell/Flow';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import useQueryParams from '@/hooks/utils/useQueryParams';

import messages from './messages';
import { useUpsellUserState } from './useUpsellUserState';

export const useUpsellBannerContent = () => {
	const userUpsellState = useUpsellUserState();
	const { isModalOpen, setIsModalOpen } = useSimplifiedSignupModalContext();
	const query = useQueryParams();

	function onBannerClick() {
		setIsModalOpen(true);
	}

	const getUpsellBannerState = (): MessageDescriptor | null => {
		/* logic to determine what the copy should be */

		const isOrganicTraffic = query?.utm_medium !== 'paid';

		if (!isOrganicTraffic) {
			return null;
		}

		switch (userUpsellState) {
			case 'loggedOut':
				return messages.userLoggedOut;
			case 'neverTrialed':
				return messages.neverSubsAndChurnedUsersWithoutTrials;
			case 'churnedAndTrialed':
				return messages.churnedUsersWithTrial;
			case 'activeCalmPremiumUser':
				return null;
			default:
				return null;
		}
	};

	return {
		onClick: onBannerClick,
		config: WebAppUpsellFlow,
		isModalOpen,
		upsellCopy: getUpsellBannerState(),
	};
};
