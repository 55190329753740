import { cloneDeep } from 'lodash';
import { useEffect } from 'react';

import { AccountScreenConfig } from '@/components/SimplifiedSignup/Account/types';
import { useSimplifiedSignupContext } from '@/components/SimplifiedSignup/Context';
import { PaymentScreenConfig } from '@/components/SimplifiedSignup/Payment/types';
import LockedContentFlow from '@/components/SimplifiedSignup/configs/LockedContent/Flow';
import { isProgramOrGuide } from '@/components/app/cards/PacksList/types';
import { usePacksContext } from '@/components/app/layout/PacksProvider';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';

import { useUserState } from '../store';

export default function useLockedContentUpsell() {
	const { isModalOpen, setIsModalOpen } = useSimplifiedSignupModalContext();
	const { flowConfig, setFlowConfig } = useSimplifiedSignupContext();
	const user = useUserState();
	const { currentPackItem } = usePacksContext();

	useEffect(() => {
		if (user?.subscription?.valid) {
			return;
		}
		const _flowConfig = cloneDeep(LockedContentFlow);
		if (currentPackItem?.image.url) {
			const subtitle = isProgramOrGuide(currentPackItem) ? currentPackItem.narrator_name : undefined;
			const contentType = isProgramOrGuide(currentPackItem) ? currentPackItem.content_type : undefined;
			const contentBasedImage = {
				src: currentPackItem?.image.url,
				isThumbnail: true,
				title: currentPackItem.title,
				subtitle,
				description: contentType,
			};
			(_flowConfig.screens.account as AccountScreenConfig).contentBasedImage = contentBasedImage;
			(_flowConfig.screens.payment as PaymentScreenConfig).contentBasedImage = contentBasedImage;
			setFlowConfig(_flowConfig);
			setIsModalOpen(true);
		}
	}, [currentPackItem, setFlowConfig, setIsModalOpen, user?.subscription?.valid]);

	return {
		isModalOpen,
		flowConfig,
	};
}
