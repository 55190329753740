import { defineMessages } from 'react-intl';

const messages = defineMessages({
	signupFormHeading: {
		id: 'lockedContent.signup.title',
		defaultMessage: 'Sign up for a free account today',
		description: 'The title for the Calm signup component',
	},
	loginFormHeading: {
		id: 'lockedContent.login.title',
		defaultMessage: 'Log into your Calm account',
		description: 'The title for the Calm login component',
	},
	accountValuePropHeader: {
		id: 'webAppUpsell.acount.valueproplist.header2git ',
		defaultMessage: 'Get 40% Off Calm Premium - Today Only',
		description: 'Header for account value props',
	},
	accountValueProp1Head: {
		id: 'lockedContent.account.valueprop1.header',
		defaultMessage: 'Easy tools for immediate relief',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	accountValueProp1Body: {
		id: 'lockedContent.account.valueprop1.body',
		defaultMessage:
			'50,000+ minutes of life-changing content and tools to help you manage your stress or anxiety.',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	accountValueProp2Head: {
		id: 'lockedContent.account.valueprop2.header',
		defaultMessage: 'Learn from the best',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	accountValueProp2Body: {
		id: 'lockedContent.account.valueprop2.body',
		defaultMessage: 'Taught by world-class mindfulness experts and narrated by soothing and familiar voices.',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	accountValueProp3Head: {
		id: 'lockedContent.account.valueprop3.header',
		defaultMessage: 'Backed by research',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	accountValueProp3Body: {
		id: 'lockedContent.account.valueprop3.body',
		defaultMessage:
			'In a randomized controlled study, those who used Calm regularly saw a reduction of anxiety symptoms by 24% (on average).',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValuePropHeader: {
		id: 'webAppUpsell.payment.valueproplist.header',
		defaultMessage: 'Access our award-winning content with 40% off Calm Premium.',
		description: 'Value props of calm with 40% off Calm Premium',
	},
	paymentValueProp1Head: {
		id: 'lockedContent.payment.valueprop1.header',
		defaultMessage: 'Avoid the overwhelm',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValueProp1Body: {
		id: 'lockedContent.payment.valueprop1.body',
		defaultMessage:
			'Quiet your mind, reduce stress, and experience instant relief with easy-to-follow meditations.',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValueProp2Head: {
		id: 'lockedContent.payment.valueprop2.header',
		defaultMessage: 'Leave the all-nighters for sleep',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValueProp2Body: {
		id: 'lockedContent.payment.valueprop2.body',
		defaultMessage:
			'Relax before bed with soundscapes, breathing exercises, and Sleep Stories narrated by Harry Styles, Camila Cabello, and more.',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValueProp3Head: {
		id: 'lockedContent.payment.valueprop3.header',
		defaultMessage: 'Prioritize self-care',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	paymentValueProp3Body: {
		id: 'lockedContent.payment.valueprop3.body',
		defaultMessage:
			'Learn how to create a daily practice to build mental strength and resilience with tools that works for your schedule.',
		description: 'Value Proposition to the user of benefits of Calm',
	},
	terms: {
		id: 'lockedContent.payment.terms.short2',
		defaultMessage:
			'This yearly membership gives you unlimited access to Calm’s entire library of content. You will be charged {price} for a 1 year subscription that will renew at {originalPrice}, billed annually.',
		description: 'A line to inform the user of the terms of their trial',
	},
	offerTerms: {
		id: 'purchaseTerms.noTrialTermsEligibility2',
		defaultMessage:
			'By clicking "Get Calm Premium", you agree to be automatically billed for Calm Premium at {price} for a 1 year subscription that will renew at {originalPrice}, billed annually. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is not eligible for trial',
	},
	noTrialTermsMonthly: {
		id: 'purchaseTerms.noTrialTermsEligibilityMonthly',
		defaultMessage:
			'By clicking "Get Calm Premium", you agree to be automatically billed for Calm Premium at {price} for a 1 month subscription that will renew at {originalPrice}, billed monthly. <cancelAnytimeLink>Cancel anytime</cancelAnytimeLink>.',
		description: 'Terms for if user is not eligible for trial and getting monthly subscription',
	},
	handoffCTALabel: {
		id: 'lockedContent.handoff.CTA.label',
		defaultMessage: 'Play content on web app',
		description: 'The button label of CTA',
	},
});

export default messages;
