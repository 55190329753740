import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useUpsellBannerContent } from '@/hooks/app/useUpsellBannerContent/useUpsellBannerContent';

import { useSimplifiedSignupContext } from '../SimplifiedSignup/Context';
import messages from './messages';
import {
	ChevronRightCenterd,
	IconAndCopyWrapper,
	UpsellBannerWrapper,
	Time,
	TimeIconWrapper,
} from './styles';

const UpsellBanner = () => {
	const { formatMessage } = useIntl();
	const { upsellCopy, config } = useUpsellBannerContent();
	const { isModalOpen, setIsModalOpen } = useSimplifiedSignupModalContext();
	const { setFlowConfig } = useSimplifiedSignupContext();

	function _onClick() {
		if (!isModalOpen) {
			setFlowConfig(config);
			setIsModalOpen(true);
		}
	}

	if (!upsellCopy) {
		return null;
	}

	return (
		<UpsellBannerWrapper
			aria-label={formatMessage(messages.upsellBannerAriaLabel)}
			tabIndex={0}
			role="button"
			onClick={_onClick}
		>
			<IconAndCopyWrapper>
				<TimeIconWrapper>
					<Time />
				</TimeIconWrapper>
				<Text color="white" size={FontSizes.base} weight={FontWeights.Medium} noMargin>
					{formatMessage(upsellCopy)}
				</Text>
			</IconAndCopyWrapper>
			<ChevronRightCenterd />
		</UpsellBannerWrapper>
	);
};

export default UpsellBanner;
