import { FontSizes } from '@calm-web/design-system';

import Account from '@/components/SimplifiedSignup/Account';
import { AccountScreenConfig } from '@/components/SimplifiedSignup/Account/types';
import Handoff from '@/components/SimplifiedSignup/Handoff';
import { HandoffScreenConfig } from '@/components/SimplifiedSignup/Handoff/types';
import Payment from '@/components/SimplifiedSignup/Payment';
import { PaymentScreenConfig } from '@/components/SimplifiedSignup/Payment/types';
import { CopyItem } from '@/components/valuePropList';
import { RouterPush } from '@/hooks/utils/useRouterPush';

import ControlScreenConfigs from '../Control/Screens';
import messages from './messages';

const activeScreenSet = {
	account: Account as React.FC,
	payment: Payment as React.FC,
	handoff: Handoff as React.FC,
};

export const accountValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: messages.accountValueProp1Head,
		body: messages.accountValueProp1Body,
	},
	{
		id: 'valueProp2',
		header: messages.accountValueProp2Head,
		body: messages.accountValueProp2Body,
	},
	{
		id: 'valueProp3',
		header: messages.accountValueProp3Head,
		body: messages.accountValueProp3Body,
	},
];

export const paymentValueProps: Array<CopyItem> = [
	{
		id: 'valueProp1',
		header: messages.paymentValueProp1Head,
		body: messages.paymentValueProp1Body,
	},
	{
		id: 'valueProp2',
		header: messages.paymentValueProp2Head,
		body: messages.paymentValueProp2Body,
	},
	{
		id: 'valueProp3',
		header: messages.paymentValueProp3Head,
		body: messages.paymentValueProp3Body,
	},
];

const WebAppUpsellAccountScreenConfig: AccountScreenConfig = {
	...ControlScreenConfigs.account,
	backgroundKey: 'gray0',
	name: 'account',
	component: activeScreenSet.account,
	nextScreen: 'payment',
	upsellBullets: accountValueProps,
	upsellHeader: () => ({ message: messages.accountValuePropHeader }),
	brandingPosition: 'left',
	hasPremiumSubCallback: (_routerPush: RouterPush, setIsModalOpen) => {
		if (setIsModalOpen) setIsModalOpen(false);
	},
	showHeader: false,
	heading: ({ authFormMode }) => {
		if (authFormMode === 'login') {
			return { message: messages.loginFormHeading };
		} else {
			return { message: messages.signupFormHeading };
		}
	},
	limitedTimeOffer: false,
	valuePropsTitleSize: FontSizes['2xl'],
	authHeaderSize: FontSizes.lg,
};
const WebAppUpsellPaymentScreenConfig: PaymentScreenConfig = {
	...ControlScreenConfigs.payment,
	component: activeScreenSet.payment,
	name: 'payment',
	nextScreen: 'handoff',
	showHeader: false,
	upsellBullets: paymentValueProps,
	heading: () => ({ message: messages.paymentValuePropHeader }),
	showToggle: false,
	brandingPosition: 'left',
	terms: messages.terms,
	offerTerms: messages.offerTerms,
};
const WebAppUpsellHandoffScreenConfig: HandoffScreenConfig = {
	component: activeScreenSet.handoff,
	name: 'handoff',
	nextScreen: null,
	showHeader: false,
	images: {},
	bullets: {},
	userCanExit: false,
	heading: () => messages.signupFormHeading,
	CTA: {
		onClick: () => {},
		label: messages.handoffCTALabel,
	},
};

export default {
	account: WebAppUpsellAccountScreenConfig,
	payment: WebAppUpsellPaymentScreenConfig,
	handoff: WebAppUpsellHandoffScreenConfig,
};
