import kebabCase from 'lodash/kebabCase';

import TabNavLinks from '@/components/app/ui/TabNavLinks';
import { useAnalytics } from '@/hooks/analytics';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import { TagsListProps } from './types';

const TagsList = ({ tags, activeTag, packFeedName }: TagsListProps) => {
	const { logEvent } = useAnalytics();
	const routerPush = useRouterPush();

	const onTagClick = async (navItemTitle: string) => {
		logEvent({
			eventName: 'Section Tags : Tag : Clicked',
			eventProps: {
				title: navItemTitle,
			},
		});

		const nextTag = tags.find(tag => tag.title === navItemTitle);

		const tagSlug = kebabCase(nextTag?.title);
		await routerPush(`/app/${packFeedName}/${tagSlug}`);
	};

	const navItems = tags
		.filter(({ title }) => title !== 'Available Offline' && title !== 'Downloads')
		.map(tag => {
			const tagSlug = kebabCase(tag.title);
			const href = `/app/${packFeedName}/${tagSlug}`;
			return { ...tag, href };
		});

	if (navItems.length <= 1) return null;

	return (
		<TabNavLinks
			navItems={navItems}
			onItemClick={onTagClick}
			activeNavItem={activeTag ?? tags.filter(tag => tag.feed_id)[0]}
		/>
	);
};

export default TagsList;
