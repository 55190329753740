import styled from 'styled-components';

import { Text, minWidth, FontWeights } from '@calm-web/design-system';

export const Wrapper = styled.div`
	display: grid;
	grid-column-gap: 16px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-bottom: 24px;
	max-width: 1920px;

	${minWidth('tablet')} {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		margin-bottom: 16px;
	}

	${minWidth('desktop')} {
		grid-column-gap: 24px;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		margin-bottom: 24px;
	}

	${minWidth('desktopLarge')} {
		grid-column-gap: 26px;
		grid-template-columns: repeat(6, minmax(0, 1fr));
		margin-bottom: 26px;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
`;

export const Title = styled(Text).attrs({
	el: 'h2',
	styleAs: 'h5',
	color: 'white',
	weight: FontWeights.Regular,
})``;

export const Subtitle = styled(Text).attrs({
	el: 'p',
	color: 'white',
})`
	max-width: 600px;
	width: 100%;
`;
