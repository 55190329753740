import styled from 'styled-components';

import { Text, TextElements, units } from '@calm-web/design-system';

interface ParagraphPackProps {
	id: string;
	text: string;
}

const Wrapper = styled.div`
	margin-bottom: ${units(4)};
`;

const PackText = styled(Text).attrs({
	el: TextElements.P,
	noMargin: true,
	color: 'white',
})``;

const ParagraphPack = ({ id, text }: ParagraphPackProps) => {
	return (
		<Wrapper key={id}>
			<PackText>{text}</PackText>
		</Wrapper>
	);
};

export default ParagraphPack;
