import { FlowConfig } from '@/components/SimplifiedSignup/Context/types';
import { FORTY_PERCENT_OFF_COUPON } from '@/components/app/layout/DiscountModal';

import WebAppUpsellScreensConfig from './Screens';

const WebAppUpsellFlow: FlowConfig = {
	activeScreenKey: 'account',
	activeScreenConfig: WebAppUpsellScreensConfig.account,
	name: 'WebAppUpsell',
	pageName: 'locked-content-upsell',
	screens: {
		account: WebAppUpsellScreensConfig.account,
		payment: WebAppUpsellScreensConfig.payment,
		handoff: WebAppUpsellScreensConfig.handoff,
	},
	plan: 'yearly',
	coupon: FORTY_PERCENT_OFF_COUPON,
	discount: 0.4,
	allowChurned: true,
	hasFreeTrial: false,
	offerCountries: null,
	offerCurrencies: null,
	isWithinModal: true,
};

export default WebAppUpsellFlow;
