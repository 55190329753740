import { useState } from 'react';
import { useWindowSize } from 'react-use';

import ContentCard, { getCardSize, isSupportedPackItem } from '@/components/app/cards/ContentCard';
import PackClassLink from '@/components/app/ui/PackClassLink';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';

import CarouselNav from '../CarouselNav';
import { PackCardItem } from '../PacksList/types';
import {
	CarouselSlide,
	CarouselWrapper,
	RightAlignedContainer,
	Title,
	TitleContainer,
	Wrapper,
} from './styles';
import { CardContainerProps } from './types';
import {
	getCellSpacing,
	getNumBannerCardsToShow,
	getNumCarouselCardsToShow,
	getSlideDimensions,
	getWrapperOffset,
} from './utils';

const CardCarousel = ({
	pack,
	hideTitle,
	contentCardOnClick,
	displayType = 'default',
	packFeedName,
	// TODO: Eventually replace this with display_type when we move to collections
	narrowTileWidth = false,
}: CardContainerProps) => {
	const { width: winWidth } = useWindowSize();
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const { flagValues, flagLoading } = useFeatureFlags('support-pack-of-packs-on-web');
	const hasSupportPackOfPacks = !flagLoading && flagValues['support-pack-of-packs-on-web'];

	const supportedPacks = pack.items.filter(item =>
		isSupportedPackItem(item, hasSupportPackOfPacks as string),
	) as PackCardItem[];

	const showTitle = Boolean(!hideTitle && pack.title);

	const cardSize = getCardSize(displayType);
	const numCardsToShow =
		cardSize === 'banner' ? getNumBannerCardsToShow(winWidth) : getNumCarouselCardsToShow(winWidth);
	const cellSpacing = getCellSpacing(winWidth);

	const [slideWidth, slideHeight] = getSlideDimensions(winWidth, cardSize, numCardsToShow, 1.34);

	if (!supportedPacks.length) return null;

	return (
		<>
			<TitleContainer $showTitle={showTitle}>
				{showTitle && <Title data-testid="card-carousel-title">{pack.title}</Title>}
				<RightAlignedContainer>
					{supportedPacks.length > numCardsToShow && (
						<CarouselNav
							numSlides={supportedPacks.length}
							currentSlideIndex={currentSlideIndex}
							setCurrentSlideIndex={setCurrentSlideIndex}
							numCardsToShow={numCardsToShow}
						/>
					)}
					{pack.has_more && pack.pack_class !== 'todays-dailies' && <PackClassLink pack={pack} />}
				</RightAlignedContainer>
			</TitleContainer>
			<Wrapper>
				<CarouselWrapper
					$gap={cellSpacing}
					$leftOffset={getWrapperOffset(slideWidth, currentSlideIndex, cellSpacing)}
				>
					{supportedPacks.map((packItem, index) => (
						<CarouselSlide
							$width={`${slideWidth}px`}
							$height={`${slideHeight}px`}
							key={'guide_id' in packItem ? packItem.guide_id : packItem.title}
						>
							<ContentCard
								packFeedName={packFeedName}
								pack={pack}
								card={packItem}
								isHidden={currentSlideIndex > index && index < supportedPacks.length - numCardsToShow + 1}
								onClick={contentCardOnClick}
								narrowTileWidth={narrowTileWidth}
								displayType={displayType}
							/>
						</CarouselSlide>
					))}
				</CarouselWrapper>
			</Wrapper>
		</>
	);
};

export default CardCarousel;
