import { DateTime } from 'luxon';

export const hourOfDay = {
	MORNING: 'morning',
	AFTERNOON: 'afternoon',
	EVENING: 'evening',
} as const;

// DO NOT USE SERVER SIDE (i.e. make sure it executes after/on first paint)
// This is built out with the assumption that pages will be loading this
export function useTimeOfDay(): string {
	const currentHour = DateTime.now().hour;
	if (currentHour > 6 && currentHour <= 12) {
		return hourOfDay.MORNING;
	}
	if (currentHour > 12 && currentHour <= 17) {
		return hourOfDay.AFTERNOON;
	}

	return hourOfDay.EVENING;
}
