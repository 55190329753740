import styled from 'styled-components';

import { IconButton, minWidth } from '@calm-web/design-system';

import ChevronLeft from 'icons/chevron-left-aligned.svg';

export const Wrapper = styled.nav`
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
	margin-top: 24px;

	${minWidth('desktop')} {
		justify-content: flex-end;
	}
`;

export const Arrow = styled(IconButton).attrs({
	Icon: ChevronLeft,
	color: 'white',
})`
	&&& {
		background: ${p => (p.isDisabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.4)')};
		height: 32px;
		margin-left: 16px;
		width: 32px;
		min-height: unset;
		min-width: unset;
		padding: 0;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& svg {
			height: 20px;
		}
		&:last-of-type {
			transform: rotate(180deg);
		}
	}
`;
