import Link from 'next/link';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Tag } from '@/components/app/cards/TagsList/types';

import messages from './messages';
import { NavItem, NavItemsSelect, Wrapper } from './styles';

export interface TabNavLink {
	title: string;
	href: string;
}

export interface TabNavLinksProps {
	navItems: TabNavLink[];
	activeNavItem?: Tag;
	onItemClick?: (navItemTitle: string) => void;
}

const TabNavLinks = ({ navItems, activeNavItem, onItemClick }: TabNavLinksProps) => {
	const { formatMessage } = useIntl();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Wrapper aria-label={formatMessage(messages.navAriaLabel)}>
			<ul>
				{navItems.map(navItem => {
					const isActive = navItem.title === activeNavItem?.title;
					return (
						<li key={navItem.title}>
							<Link passHref href={navItem.href} legacyBehavior>
								<NavItem
									$isActive={isActive}
									aria-current={isActive}
									tabIndex={isActive ? -1 : 0}
									onClick={() => onItemClick && onItemClick(navItem.title)}
								>
									{navItem.title}
								</NavItem>
							</Link>
						</li>
					);
				})}
			</ul>
			<NavItemsSelect
				aria-expanded={isMenuOpen}
				onChange={e => {
					setIsMenuOpen(false);
					if (onItemClick) {
						onItemClick(e.target.value);
					}
				}}
				onBlur={() => setIsMenuOpen(false)}
				onKeyDown={() => setIsMenuOpen(!isMenuOpen)}
				onClick={() => setIsMenuOpen(!isMenuOpen)}
				value={activeNavItem?.title || navItems[0].title}
			>
				{navItems.map(navItem => (
					<option
						key={navItem.title}
						value={navItem.title}
						aria-selected={navItem.title === activeNavItem?.title}
					>
						{navItem.title}
					</option>
				))}
			</NavItemsSelect>
		</Wrapper>
	);
};

export default TabNavLinks;
