import { Dispatch, SetStateAction } from 'react';

import { Arrow, Wrapper } from './styles';

const CarouselNav = ({
	numSlides,
	currentSlideIndex,
	setCurrentSlideIndex,
	numCardsToShow,
}: {
	numSlides: number;
	currentSlideIndex: number;
	setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
	numCardsToShow: number;
}) => {
	const isAtBeginning = currentSlideIndex === 0;
	const isAtEnd = currentSlideIndex >= numSlides - numCardsToShow + 1;
	const onPrev = () => {
		if (!isAtBeginning) {
			setCurrentSlideIndex(currentSlideIndex - 1);
		}
	};

	const onNext = () => {
		if (!isAtEnd) {
			setCurrentSlideIndex(currentSlideIndex + 1);
		}
	};

	return (
		<Wrapper>
			<Arrow
				onPress={onPrev}
				isDisabled={isAtBeginning}
				aria-label="Scroll carousel towards previous items"
			/>
			<Arrow onPress={onNext} isDisabled={isAtEnd} aria-label="Scroll carousel towards next items" />
		</Wrapper>
	);
};

export default CarouselNav;
