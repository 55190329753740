import Link from 'next/link';
import { useIntl } from 'react-intl';

import messages from './messages';
import { RightArrow, Wrapper } from './styles';
import { PackClassLinkProps } from './types';

const PackClassLink = ({ pack, cta, theme = 'small' }: PackClassLinkProps) => {
	const { formatMessage } = useIntl();

	return (
		<Link passHref href={`/app/class/${pack.pack_class}`} legacyBehavior>
			<Wrapper
				$theme={theme}
				aria-label={formatMessage(messages.ariaLabel, {
					title: pack.title,
				})}
			>
				{cta ?? formatMessage(messages.seeAllCta)}
				<RightArrow $theme={theme} />
			</Wrapper>
		</Link>
	);
};

export default PackClassLink;
