import { defineMessages, MessageDescriptor } from 'react-intl';

import { hourOfDay } from '@/hooks/utils/useTimeOfDay';

const messages = defineMessages({
	goodMorning: {
		id: 'app.components.screenTitle.goodMorningCap',
		defaultMessage: 'Good Morning',
		description: "This will be used as a total phrase with the user's name. Ex. Good Morning, John!",
	},
	goodAfternoon: {
		id: 'app.components.screenTitle.goodAfternoonCap',
		defaultMessage: 'Good Afternoon',
		description: "This will be used as a total phrase with the user's name. Ex. Good Afternoon, John!",
	},
	goodEvening: {
		id: 'app.components.screenTitle.goodEveningCap',
		defaultMessage: 'Good Evening',
		description: "This will be used as a total phrase with the user's name. Ex. Good Evening, John!",
	},
});

export const getGreetingHeader = (timeOfDay: string): MessageDescriptor => {
	switch (timeOfDay) {
		case hourOfDay.AFTERNOON:
			return messages.goodAfternoon;
		case hourOfDay.EVENING:
			return messages.goodEvening;
		case hourOfDay.MORNING:
		default:
			return messages.goodMorning;
	}
};
