import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights } from '@calm-web/design-system';

import ChevronLeft from 'icons/chevron-left-aligned.svg';

const largeWrapperStyle = css`
	${fontSize(FontSizes.xl)}
	${fontWeight(FontWeights.Medium)}
  margin-left: 0;
`;

const smallWrapperStyle = css`
  ${fontSize(FontSizes.base)}
  ${fontWeight(FontWeights.Regular)}}
  margin-left: 2rem;
`;

export const Wrapper = styled.a<{ $theme: 'large' | 'small' }>`
	align-items: center;
	color: ${palette('white')};
	display: flex;
	${p => (p.$theme === 'large' ? largeWrapperStyle : smallWrapperStyle)};
	text-decoration: none;
`;

export const RightArrow = styled(ChevronLeft)<{ $theme: 'large' | 'small' }>`
	height: ${p => (p.$theme === 'large' ? '36px' : '20px')};
	margin-left: 2px;
	transform: rotate(180deg);
	width: ${p => (p.$theme === 'large' ? '40px' : '24px')};

	& path {
		fill: ${palette('white')};
	}
`;
