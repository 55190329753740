import { defineMessages } from 'react-intl';

export default defineMessages({
	seeAllCta: {
		id: 'app.cardCarousel.seeAllCta',
		defaultMessage: 'See All',
		description: 'Link to view all content items in carousel',
	},
	ariaLabel: {
		id: 'app.seeAllCta.ariaLabel',
		defaultMessage: 'See all items from {title}',
		description: 'The description of the link sent to a screenreader',
	},
});
